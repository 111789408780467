/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint react/no-danger:0 */

import React, { useEffect } from 'react';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import useWindowWidth from 'hooks/browser/useWindowWidth';

import TermsContent from './TermsContent';

const Terms = ({ type, urlKey }) => {

  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (type || urlKey) {
      window.scrollTo(0, 0);
    }
  }, [type, urlKey]);

  return (
    <BlueHeaderOnWhiteLayout>
      <div className="viewstrap">
        <div style={{
          textAlign: 'left',
          width: windowWidth >= 1000 ? '1000px' : null,
          margin: windowWidth >= 1000 ? '0 auto' : '20px'
        }}>
          <TermsContent type={type} urlKey={urlKey}/>
        </div>
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default Terms;