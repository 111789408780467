/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint react/no-danger:0 */

import React, { useEffect } from 'react';

import FormattedDate from 'components/FormattedDate';

import LoadingIndicator from 'components/LoadingIndicator';

import DOMPurify from 'dompurify';

import useApi from 'api/hooks/useApi';
import { getTerms } from 'api/terms';

import './index.scss';

const TermsContent = ({ type, urlKey, showHeading = true, showLastUpdated = true }) => {

  const api = useApi(getTerms, 'GetTerms', {
    popupError: false
  });

  useEffect(() => {
    if (urlKey) {
      api.call({
        urlKey
      });
    } else if (type) {
      api.call({
        type
      });
    }
  }, [type, urlKey]);

  if (api.isLoading()) {
    return <LoadingIndicator />;
  }

  const terms = api.getResponse()?.data?.[0];

  if (!terms) {
    return null;
  }

  return (
    <div className="viewstrap">
      <div className="terms-content">

        {showHeading
          ? (
            <div className="headline">
              {terms?.title}
            </div>
          )
          : null}

        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(terms?.content) }}></div>

        {showLastUpdated
          ? (
            <div style={{ marginTop: '50px' }}>
              <h2>
                Last Updated: <FormattedDate value={terms?.updatedDate} />
              </h2>
            </div>
          )
          : null}

      </div>
    </div>
  );
};

export default TermsContent;