/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Switch } from 'react-router';
import Terms from 'views/Terms';
import ViewpostControls from 'views/Terms/viewpostControls';
import { useParams } from 'react-router';
import { NotFoundRedirect } from './utils';

function TermsView() {
  const { urlKey } = useParams();
  return <Terms urlKey={urlKey} />;
}

export default ({ match }) => (
  <Switch>

    {/* Special hard coded info page for compliance */}
    <Route path={`${match.url}/vp-controls`} exact={true}>
      <ViewpostControls />
    </Route>

    <Route path={`${match.url}`} exact={true}>
      <Terms type="TermsOfUse" />
    </Route>

    {/* One route to support types dynamically (used by emails) */}
    <Route path={`${match.url}/:urlKey`} component={TermsView} />
    {NotFoundRedirect}
  </Switch>
);
