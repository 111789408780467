/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';

import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import Icon, { IconTypes } from 'components/Icon';
import { FlexColumn, FlexGrid } from 'components/Viewstrap/Grid';

import './index.scss';

const Accordion = ({
  className,
  expandedSections,
  headerColor,
  // (key) => {}
  onToggle,
  // List of [ key, header, message ]
  sections,
  hideBorder
}) => {
  return (
    <div
      className={classNames(
        'accordion',
        className
      )}
    >
      {sections.map(
        ([ key, header, message ]) => {
          const expanded = expandedSections?.includes(key);
          return (
            <div
              className={classNames(
                'accordion-section',
                hideBorder ? 'accordion-no-border' : '',
                { expanded }
              )}
              key={key}
              onClick={() => onToggle(key)}
            >
              <FlexGrid className="pad-4-bottom">
                <FlexColumn className="header-content">
                  <HeaderText
                    color={headerColor}
                    variant={HeaderTextVariants.Small}
                  >
                    {header}
                  </HeaderText>
                </FlexColumn>
                <FlexColumn className="header-arrow">
                  <Icon
                    className="arrow-indicator"
                    type={IconTypes.SortArrow}
                  />
                </FlexColumn>
              </FlexGrid>
              <div className="body-content">
                {message}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default Accordion;